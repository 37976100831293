.light-theme{
  /* Set the 'light' theme */
  --background-color: white;
  --oos-color: orange;
}

.dark-theme{
  /* Set the 'dark' theme */
  --background-color: rgb(12,12,15);
  --oos-color: yellow;
}

body {
  text-align: left;
  background-color: var(--background-color, rgb(12,12,15));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2vw;
  font-family: 'digital-7Italic', cursive;
}

@font-face {
  font-family: 'digital-7Italic';
  font-style: italic;
  font-weight: 400;
  src: url("./digital-7italic.eot"); /* IE9 Compat Modes */
  src: url("./digital-7italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./digital-7italic.otf") format("opentype"), /* Open Type Font */
  url("./digital-7italic.svg") format("svg"), /* Legacy iOS */
  url("./digital-7italic.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./digital-7italic.woff") format("woff"), /* Modern Browsers */
  url("./digital-7italic.woff2") format("woff2"); /* Modern Browsers */
}

@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: "Digital-7MonoItalic";
  src: url("./digital-7monoitalic.eot"); /* IE9 Compat Modes */
  src: url("./digital-7monoitalic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./digital-7monoitalic.otf") format("opentype"), /* Open Type Font */
  url("./digital-7monoitalic.svg") format("svg"), /* Legacy iOS */
  url("./digital-7monoitalic.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./digital-7monoitalic.woff") format("woff"), /* Modern Browsers */
  url("./digital-7monoitalic.woff2") format("woff2"); /* Modern Browsers */
}

svg:not(:root) {
  overflow: visible;
}

.digit {
  font-family: 'Digital-7MonoItalic', monospace;
  color: green;
  font-weight: bold;
}

.label {
  font-family: 'digital-7Italic', cursive;
  color: darkorange;
  font-weight: bold;
}

.out-of-spec {
  color: var(--oos-color);
}

.lowercase {
  font-size: 1vw;
}

hr {
  border: none;
  height: 1px;
  background: darkblue;
}

.top-left {
  position:absolute;
  top:0;
  left:0;
  border-bottom: 1px solid darkblue;
  border-right: 1px solid darkblue;
  border-radius: 16px;
}

.mid-left {
  position:absolute;
  top:40%;
  left:0;
  border-bottom: 1px solid darkblue;
  border-right: 1px solid darkblue;
  border-radius: 16px;
}

.mid-right {
  position:absolute;
  top:40%;
  right:0;
  border-bottom: 1px solid darkblue;
  border-right: 1px solid darkblue;
  border-radius: 16px;
}

.bottom-left {
  position:absolute;
  bottom:0;
  left:0;
  border-top: 1px solid darkblue;
  border-right: 1px solid darkblue;
  border-radius: 16px;
}

.top-right {
  position:absolute;
  top:0;
  right:0;
  border-bottom: 1px solid darkblue;
  border-left: 1px solid darkblue;
  border-radius: 16px;
}

.bottom-right {
  position:absolute;
  bottom:0;
  right:0;
  border-top: 1px solid darkblue;
  border-left: 1px solid darkblue;
  border-radius: 16px;
}

.container {
  height: 100%;
  width: 100%;
}

#root {
  width: calc(100% - 100px);
  height: 100%;
}

.speedometer {
  text-align: center;
  /*vertical-align: center;*/
}

.cabinet-door {
  text-align: center;
  font-family: 'Digital-7MonoItalic', monospace;
  color: green;
  font-weight: bold;
  font-size: 2vw;
}

.red {
  color: red;
}
